<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-setting"></i> {{ title }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <el-form :model="addguestrData" :rules="rules" ref="addguestrData" label-width="100px" class="demo-addhotelData">
        <el-form-item label="所属酒店" prop="hotel_id">
          <el-select v-model="addguestrData.hotel_id" placeholder="选择所在的酒店">
              <el-option
                v-for="item in hotellistArr"
                :key="item.id"
                :label="item.hotel_name"
                :value="item.id">
              </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客房类型" prop="room_name">
          <el-input  v-model="addguestrData.room_name" placeholder="例：大床房、标签、总统套房"></el-input>
        </el-form-item>
        <el-form-item label="可住人数" prop="hold_count">
          <el-input v-model="addguestrData.hold_count" placeholder="请输入可住人数"></el-input>
        </el-form-item>
        <el-form-item label="房间数量" prop="room_count">
          <el-input v-model="addguestrData.room_count" placeholder="请输入房间数量"></el-input>
        </el-form-item>
        <el-form-item label="剩余数量" prop="remain_count">
          <el-input v-model="addguestrData.remain_count" placeholder="请输入房间剩余数量"></el-input>
        </el-form-item>
        <el-form-item label="房间简介" prop="desc">
          <el-input type="textarea" v-model="addguestrData.desc" placeholder="请输入房间简介"></el-input>
        </el-form-item>
        <el-form-item label="房间价格" prop="price">
          <el-input v-model="addguestrData.price" placeholder="请输入房间价格"></el-input>
        </el-form-item>
        <el-form-item label="房间面积" prop="area">
          <el-input v-model="addguestrData.area" placeholder="请输入房间面积"></el-input>
        </el-form-item>
        <el-form-item label="床尺寸" prop="bed_size">
          <el-input v-model="addguestrData.bed_size" placeholder="请输入房间床尺寸"></el-input>
        </el-form-item>

        <el-form-item label="基础设施" prop="area">
          <el-checkbox-group v-model="addguestrData.facility_id_arr">
            <el-checkbox v-for="mpb in permissionBeans" :key="mpb.id" :label="mpb.id" v-model="mpb.checked">{{mpb.facility_name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="图片简介" prop="image_url">
          <el-upload
            action="/api/common/public/upload_one_image/"
            list-type="picture-card"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload">
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="取消方式" prop="mode_type">
          <el-radio-group v-model="addguestrData.mode_type">
            <el-radio :label="0">删除</el-radio>
            <el-radio :label="1">正常预定</el-radio>
            <el-radio :label="2">不开启预定</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="排序" prop="list_order">
          <el-input v-model="addguestrData.list_order" type="number"></el-input>
          <p style="color: red">（值越小越靠前）</p>
        </el-form-item>
     
        <el-form-item>
          <el-button type="primary" @click="submitForm('addguestrData')"
            >立即创建</el-button
          >
          <el-button @click="resetForm('addguestrData')">重置</el-button>
          <el-button v-on:click="back">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { get_info,post_room_list,get_room_list } from "../../api/index";
export default {
  name: "permission", 
  data() {
    return {
      title: "新增客房",
      // 添加客房
      addguestrData: {
        type:'add',
        hotel_id:this.hotel_id,//酒店id
        room_name:this.room_name,//客房类型
        hold_count:this.hold_count,//可住人数
        room_count:this.room_count,//房间总数
        remain_count:this.remain_count,//房间剩余数量
        desc:this.desc,//房间简介
        price:this.price,//房间价格
        area:this.area,//房间面积
        image_url:[],//图片
        bed_size:this.bed_size,//床尺寸
        mode_type:this.mode_type,//取消方式 1随时 2、18点后不可取消 3、不可取消
        facility_id_arr:[],//客房设施
        list_order:this.list_order//排序
      },
      hotellistArr:[], // 酒店列表
      permissionBeans:[],//基础设施列表
      // 表单验证
      rules: {
        room_name: [
          { required: true, message: "请输入客房类型", trigger: "blur" },
        ],
        hold_count: [
          { required: true, message: "请输入可住人数", trigger: "blur" },
        ],
        room_count: [
          { required: true, message: "请输入房间总数", trigger: "blur" },
        ],
        remain_count: [
          { required: true, message: "请输入剩余房间数", trigger: "blur" },
        ],
        desc: [
          { required: true, message: "请输入房间简介", trigger: "blur" },
        ],
        price: [
          { required: true, message: "请输入房间价格", trigger: "blur" },
        ],
        area: [
          { required: true, message: "请输入房间面积", trigger: "blur" },
        ],
        bed_size: [
          { required: true, message: "请输入床尺寸", trigger: "blur" },
        ],
        list_order: [
          { required: true, message: "请输入排序", trigger: "blur" },
        ],
        mode_type: [
          { required: false, message: "请选择取消方式", trigger: "change" },
        ],
        facility_id_arr: [
          { required: false, message: "请选择客房设施", trigger: "change" },
        ],
      },
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false
    };
  },
  created() {
    this.getmenulist();
  },
  methods: {
    getmenulist(){
      var hotelData ={
        type: "hotel",
      }
      get_info(hotelData).then((res) =>{
        this.hotellistArr = res.data.list
      })
      var facilitydata = {
        type:'facility',
        belong:'1'
      }
      get_info(facilitydata).then((res) =>{
        // this.permissionBeans = res.data.list
      })

    },
    
    get_guestrData(id) {
      var get_infoData = {
        id: id,
      };
      // 设置地图显示的城市 此项是必须设置的
      get_room_list(get_infoData).then((res) => {
        console.log(res);
        this.addguestrData.hotel_id = res.data.list.hotel_id;
        this.addguestrData.room_name = res.data.list.room_name;
        this.addguestrData.hold_count = res.data.list.hold_count;
        this.addguestrData.room_count = res.data.list.room_count;
        this.addguestrData.remain_count = res.data.list.remain_count;
        this.addguestrData.desc = res.data.list.desc;
        this.addguestrData.price = res.data.list.price;
        this.addguestrData.image_url = res.data.list.image_url;
        this.addguestrData.bed_size = res.data.list.bed_size;
        this.addguestrData.area = res.data.list.area;
        this.addguestrData.mode_type = res.data.list.mode_type;
        this.addguestrData.facility_id_arr = res.data.list.facility_id_arr;
        this.addguestrData.list_order = res.data.list.list_order;

        
      });
    },
    // 表单提交
    submitForm(formName) {
      console.log(this.addguestrData);
        this.$refs[formName].validate((valid) => {
          if (valid) {
            post_room_list(this.addguestrData).then((res) => {
              if (res.code == 1) {
                this.$message.success(res.msg);
              }
            });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      if (this.$route.query.id) {
        console.log("编辑");
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
               
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
    },
    back() {
      this.$router.go(-1); //返回上一层
    },
    
    //上传图片
    handleAvatarSuccess(res, file) {
      this.loading = false;
      this.addguestrData.image_url.push(res.data.filepath)
      if (res.code == 1) {
        this.$message.success(res.msg);
      }
    },
    beforeAvatarUpload(file) {
      this.loading = true;
      this.progressData = 0;
      var timer2 = setInterval(() => {
        this.progressData = this.progressData + 1;
        if (this.progressData >= 100) {
          clearInterval(timer2);
        }
      }, 200);
      const isJPG = file.type === 'image/jpeg/jpg'
      const isLt5M = file.size / 1024 / 1024 < 10;
      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG/PNG/JPEG 格式!');
      // }
      // if (!isLt5M) {
      //   this.$message.error("上传头像图片大小不能超过 10MB!");
      // }
      return isLt5M;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },    
  },
  mounted() {
    if (this.$route.query.id) {
      var id = this.$route.query.id;
      this.get_guestrData(id)
      // 获取酒店信息，编辑使用
      // this.addhotelData.hotel_name = data
      this.title = "编辑客房";
      document.title = "编辑客房";
    }
  },
  computed: {},
};
</script>

<style scoped>

.el-form {
  width: 70%;
}
#map {
  min-height: 50vh;
  margin-top: 13px;
  border: 1px solid #988c8c;
}
h1 {
  text-align: center;
  margin: 30px 0;
}
p {
  line-height: 30px;
  margin-bottom: 10px;
}
.logout {
  color: #409eff;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>